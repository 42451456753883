import { defineStore } from 'pinia';
import { handleError } from '@/devicehub/utils/error';
import { useStudyDataExportApi } from './studyDataExportApi';

export interface DataExportState {
  loading: boolean;
  jobId: string | null;
  exportInProgress: boolean;
  canDownload: boolean;
  page: number;
}

export const useDataExportStore = defineStore({
  id: 'dataExport',
  state: (): DataExportState => ({
    loading: false,
    jobId: null,
    exportInProgress: false,
    canDownload: false,
    page: 0,
  }),
  getters: {
    downloadLink(): string {
      const studyDataExportApi = useStudyDataExportApi();
      return `${studyDataExportApi.basePath}/download?job_id=${this.jobId}`;
    },
  },
  actions: {
    async startDataExport(
      startTime: Date,
      endTime: Date,
      deviceUUID: string,
      streamId: string,
    ): Promise<void> {
      const studyDataExportApi = useStudyDataExportApi();
      this.loading = true;
      try {
        const response =
          await studyDataExportApi.appApi.triggerDataExportTriggerDataExportGet(
            startTime.toISOString(),
            endTime.toISOString(),
            deviceUUID,
            streamId,
          );
        this.jobId = response.data;
        this.page = 1;
        this.canDownload = false;
        this.exportInProgress = true;
        this.scheduleUpdateStatus();
      } catch (error) {
        this.jobId = null;
        this.canDownload = false;
        this.exportInProgress = false;
        handleError(error);
      }
      this.loading = false;
    },

    scheduleUpdateStatus(): void {
      if (this.jobId && this.exportInProgress) {
        setTimeout(() => {
          this.updateStatus();
        }, 2000);
      }
    },

    async updateStatus(): Promise<void> {
      const studyDataExportApi = useStudyDataExportApi();
      if (this.jobId) {
        try {
          const response =
            await studyDataExportApi.appApi.exportStatusExportStatusGet(
              this.jobId,
            );
          if (response.data.completed) {
            this.canDownload = true;
            this.exportInProgress = false;
          }
          this.page = response.data.last_page;
        } catch (error) {
          handleError(error);
        }
        this.scheduleUpdateStatus();
      }
    },

    async download(): Promise<void> {
      const studyDataExportApi = useStudyDataExportApi();
      this.loading = true;
      if (this.jobId) {
        try {
          const response = await studyDataExportApi.appApi.downloadDownloadGet(
            this.jobId,
          );
          console.log('response', response);
        } catch (error) {
          handleError(error);
        }
      }
      this.loading = false;
    },
  },
});
